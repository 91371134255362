import Login from '../classes/Login';

export default function init(): void
{
	let login: Login;
	let element: HTMLButtonElement;
	element = <HTMLButtonElement>document.querySelector('ul.login-panel>li.login-button>button');
	
	if ( element )
	{
		login = new Login( element );
	}
	
	
}