//import Request from './Request';

/*/// <reference path="jquery.d.ts" />
declare let $: any;*/

const SEARCH: string = 'search';
const SHOW: string = 'show';

export default class FindAffiliateStatistics
{
	//public request: Request;
	
	public mainElement: HTMLElement;
	
	/**
	 * Элементы принадлежащие аффилу
	 */
	// Кнопка очистки
	private affiliateButtonErease: HTMLButtonElement;
	// Лейбел который виден всегда
	private affiliateLabel: HTMLLabelElement;
	// Скрытый select формы
	private affiliateSelect: HTMLSelectElement;
	// Данные select формы
	private affiliateSelectData: NodeList;
	// Выпадающий список с результатами
	private affiliateList: HTMLUListElement;
	// Кастомный инпут, который виден
	private customAffiliateInput: HTMLInputElement;
	// Результаты поиска
	private customAffiliateList: NodeList;
	
	/**
	 * Элементы принадлежащие домену
	 */
	// Кнопка очистки
	private domainButtonErease: HTMLButtonElement;
	// Лейбел который виден всегда
	private domainLabel: HTMLLabelElement;
	// Скрытый инпут формы
	private domainInput: HTMLInputElement;
	// Выпадающий список с результатами
	private domainList: HTMLUListElement;
	// Кастомный инпут, который виден
	private customDomainInput: HTMLInputElement;
	// Результаты поиска
	private customDomainList: NodeList;
	
	// триггер отвечающий за осуществление поиска аффилейта.
	private affiliateSearch: boolean;
	
	/**
	 * Форма фильтрации
	 */
	private mainForm: HTMLFormElement;
	
	/**
	 * Все пути для запросов
	 */
	public pathAjax: any;
	
	/**
	 * Ответ из запроса
	 */
	private data: Array<Object>;
	
	/**
	 * Конструктор приложения
	 * @param element
	 */
	public constructor( element: HTMLElement )
	{
		// Запоминаем наши элементы
		this.data = [];
		this.mainElement = element;
		this.getMainElements();
		
		// Все пути
		this.pathAjax = {
			affiliate: "/management/ajax/find/affiliate?q=",
			domainsId: {
				before: "/management/ajax/affiliate/",
				after: "/domains"
			},
			domains: "/management/ajax/find/domain?q="
		};
		
		// Проверяем, был ли запрос до обновления страницы
		this.checkRequest();
		
		// Слушатели событий
		this.customAffiliateInput.addEventListener( 'input', this.getAffiliateDataInputHandler.bind( this ) );
		this.customAffiliateInput.addEventListener( 'focus', this.showAffiliateList.bind(this) );
		//this.customAffiliateInput.addEventListener( 'blur', this.hideAffiliateList.bind(this) );
		this.customDomainInput.addEventListener( 'input', this.getDomainDataInputHandler.bind( this ) );
		this.customDomainInput.addEventListener( 'focus', this.showDomainList.bind(this) );
		//this.customDomainInput.addEventListener( 'blur', this.hideDomainList.bind(this) );
		
		// Лейблы который видны всегда
		this.affiliateLabel.addEventListener( 'click', this.showAffiliateList.bind(this) );
		this.domainLabel.addEventListener( 'click', this.showDomainList.bind(this) );
		// Кнопки очистки данных в полях
		this.affiliateButtonErease.addEventListener( 'click', this.affiliateButtonEreaseClickHandler.bind(this) );
		this.domainButtonErease.addEventListener( 'click', this.domainButtonEreaseClickHandler.bind(this) );
	}
	
	/**
	 * Функция возвращает данные из option по его value
	 */
	private getEmailFromId(id:string): string
	{
		let email: string;
		
		Array.prototype.forEach.call(
			this.affiliateSelectData,
			( option: HTMLOptionElement ) =>
			{
				if ( id === option.getAttribute( 'value' ) )
				{
					email =  option.innerText;
				}
			}
		);
		
		return email;
	}
	
	/**
	 * Функция проверяет был ли запрос до перезагрузки страницы и в случае если он был, запускат поиск по аффилу -> поиск по домену
	 */
	private checkRequest():void
	{
		if ( this.affiliateSelect.value !== '' )
		{
			let email = this.getEmailFromId(this.affiliateSelect.value);
			
			this.affiliateButtonErease.classList.add( SHOW );
			this.affiliateLabel.innerText = email;
			this.customAffiliateInput.value = email;
			//this.getAffiliateDataInputHandler();
		}
		if ( this.domainInput.value !== '' )
		{
			this.domainButtonErease.classList.add( SHOW );
			this.domainLabel.innerText = this.domainInput.value;
		}
	}
	
	/**
	 *
	 * Функция создаёт слушателей события клик у найденных элементов аффилейта
	 */
	private createListenerClickListAffil():void
	{
		this.customAffiliateList = <NodeList>this.affiliateList.querySelectorAll( 'li' );
		
		Array.prototype.forEach.call(
			this.customAffiliateList,
			( item: HTMLLIElement ) =>
			{
				item.addEventListener( 'click', this.customAffiliateListOptionsClickHandler.bind(this) );
			}
		);
	}
	/**
	 * Функция создаёт слушателей события клик у найденных элементов домена
	 */
	private createListenerClickListDomain():void
	{
		this.customDomainList = <NodeList>this.domainList.querySelectorAll( 'li' );
		
		Array.prototype.forEach.call(
			this.customDomainList,
			( item: HTMLLIElement ) =>
			{
				item.addEventListener( 'click', this.customDomainListOptionsClickHandler.bind(this) );
			}
		);
	}
	
	private createListAffiliate():void
	{
		Array.prototype.forEach.call(
			this.affiliateSelectData,
			( option: HTMLOptionElement ) =>
			{
				this.addSelectAffil(option);
			}
		);
		
		this.createListenerClickListAffil();
	}
	
	/**
	 * Получаем все элементы форм которые учавствуют в обработке значений
	 */
	public getMainElements():void
	{
		this.affiliateButtonErease = <HTMLButtonElement>document.querySelector( 'div.affil>button' );
		this.affiliateLabel = <HTMLLabelElement>document.querySelector( 'div.affil>label' );
		this.customAffiliateInput = <HTMLInputElement>document.querySelector( 'input.custom-affil' );
		this.affiliateList = <HTMLUListElement>document.querySelector( 'ul.custom-selectpicker-affil' );
		this.affiliateSelect = <HTMLSelectElement>document.querySelector( 'select#base_reporting_filter_form_affiliate' );
		this.affiliateSelectData = <NodeList>this.affiliateSelect.querySelectorAll( 'option' );
		
		this.domainButtonErease = <HTMLButtonElement>document.querySelector( 'div.domain>button' );
		this.domainLabel = <HTMLLabelElement>document.querySelector( 'div.domain>label' );
		this.customDomainInput = <HTMLInputElement>document.querySelector( 'input.custom-domain' );
		this.domainList  = <HTMLUListElement>document.querySelector( 'ul.custom-selectpicker-domain' );
		this.domainInput = <HTMLInputElement>document.querySelector( 'input#base_reporting_filter_form_domain' );
		
		this.mainForm = <HTMLFormElement>document.querySelector( 'form[name="base_reporting_filter_form"]' );
		
		this.createListAffiliate();
	}
	
	/**
	 * Обработчий события ввода в текстовое поле у аффилейта
	 * @param event - нажатия клавиши, может и не быть
	 */
	public getAffiliateDataInputHandler( event?: KeyboardEvent ): void
	{
		// чистим список аффила
		this.emptySelectOptionsAffil();
		
		let value = this.customAffiliateInput.value;
		let find: boolean = false;
		
		Array.prototype.forEach.call(
			this.affiliateSelectData,
			( option: HTMLOptionElement ) =>
			{
				if ( option.innerText.toLowerCase().indexOf( value.toLowerCase() ) > -1 )
				{
					option.classList.add( 'show' );
					find = true;
					this.addSelectAffil(option);
				}
				else
				{
					option.classList.remove( 'show' );
				}
			}
		);
		
		if ( find === false )
		{
		
		}
		
		this.affiliateSearch = true;
		this.affiliateButtonErease.classList.add( SHOW );
		this.createListenerClickListAffil();
		
		/*if ( value.length >= 3 )
		{
			//this.createPreloaderAffil( );
			//this.createRequest( this.pathAjax.affiliate + value , "affil" );
			
		}*/
		
	}
	/**
	 * Обработчий события ввода в текстовое поле у домена
	 * @param event - нажатия клавиши, может и не быть
	 */
	public getDomainDataInputHandler( event?: KeyboardEvent ): void
	{
		let value = this.customDomainInput.value;
		
		if ( ( value.length >= 3 ) && ( !this.affiliateSearch ) )
		{
			this.createPreloaderDomain( );
			this.createRequest( this.pathAjax.domains + value , "domain" );
			this.domainButtonErease.classList.add( SHOW );
		}
	}
	
	/**
	 * Обработчик события клика по крестику, для очистки полей аффила
	 * @param e - клик мыши
	 */
	public affiliateButtonEreaseClickHandler(e?: MouseEvent):void
	{
		this.affiliateLabel.innerText = 'Affiliate';
		this.customAffiliateInput.value = '';
		this.affiliateSelect.value = '';
		this.affiliateButtonErease.classList.remove( SHOW );
		this.emptySelectOptionsAffil();
		this.affiliateSearch = false;
		
		this.createListAffiliate();
	}
	/**
	 * Обработчик события клика по крестику, для очистки полей домена
	 * @param e - клик мыши
	 */
	public domainButtonEreaseClickHandler(e: MouseEvent):void
	{
		this.domainLabel.innerText = 'Domains';
		this.customDomainInput.value = '';
		this.domainInput.value = '';
		this.domainButtonErease.classList.remove( SHOW );
		this.emptySelectOptionsDomains();
	}
	
	/**
	 * Обработка автоматического запуска поиска домена по имени пользователя
	 * Запускается после клика по аффилу ?
	 */
	public getDomainsDataFromAffil( ): void
	{
		let value = this.domainInput.getAttribute( 'data-id' );
		
		if ( value.length > 0 )
		{
			this.createPreloaderDomain( );
			this.createRequest( this.pathAjax.domainsId.before + value + this.pathAjax.domainsId.after , "domain" );
		}
	}
	
	/**
	 * Функция запуска ajax запроса
	 * @param url - куда отправляем
	 * @param type - affil или domain
	 */
	public createRequest(url: string, type?: string):any
	{
		let _that: any = this;
		
		let httpRequest: XMLHttpRequest;
		//let requestString: string;
		httpRequest = new XMLHttpRequest();
		
		if ( !httpRequest )
		{
			// Вывод сообщения
			alert('Не удалось установить связь с сервером. Возможно ваш браузер устарел.');
			return;
		}
		
		// Вдруг забыли указать данные
		if ( type === '' )
		{
			type = 'affil';
		}
		
		// Отпавляем запрос
		httpRequest.open("POST", url, true);
		httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
		httpRequest.setRequestHeader('Accept', 'application/json');
		httpRequest.send();
		
		httpRequest.onreadystatechange = function()
		{
			if (httpRequest.readyState != 4) {return;}
			
			if (httpRequest.status != 200)
			{
				// В случае ошибки сообщаем об этом
				alert(httpRequest.status + ': ' + httpRequest.statusText);
			}
			else
			{
				// Сохраняем данные запроса в глобальном объекте
				_that.data = JSON.parse(httpRequest.responseText);
				
				
				// Запускаем обработчик события прихода данных
				if ( type === "affil" )
				{
					_that.insertAffiliate();
				}
				else
				{
					_that.insertDomain();
				}
				
			}
			
		};
	}
	
	/**
	 * Функция обрабатывает запуск обработчика события прихода данных из запроса поиска афила
	 */
	/*public insertAffiliate( option: HTMLOptionElement ):void
	{
		//let id:string = option.getAttribute( 'value' );
		//let email: string = option.innerText;
		
		// проходимся по каждому элементу и вставляем его в наш список
		if ( this.data.length !== 0 )
		{
			for ( key in this.data )
			{
				this.addSelectAffil( <any>this.data[key] );
			}
			
			this.createListenerClickListAffil();
			
			// Если у нас только 1 результат, то сразу запускаем поиск по его доменам.
			//if ( this.data.length === 1 )
			//{
				//this.customAffiliateListOptionsClickHandler();
			//}
		}
		else
		{
			let obj: any = {error:'Nothing to show'};
			this.addSelectAffil( <any>obj );
		}
		
		this.createListenerClickListAffil();
		
		this.removePreloaderAffil();
	}*/
	/**
	 * Функция обрабатывает запуск обработчика события прихода данных из запроса поиска домена
	 */
	public insertDomain():void
	{
		this.emptySelectOptionsDomains();
		
		let key:any;
		
		/*for ( key in this.data )
		{
			this.addSelectDomain( <any>this.data[key] );
		}*/
		
		if ( this.data.length !== 0 )
		{
			for ( key in this.data )
			{
				this.addSelectDomain( <any>this.data[key] );
			}
			
			this.createListenerClickListDomain();
		}
		else
		{
			let obj: any = {name:'Nothing to show'};
			this.affiliateSearch = false;
			this.addSelectDomain( <any>obj );
		}
		
		this.removePreloaderDomain();
	}
	
	/**
	 * Фукнция вставки данных в список аффилейта
	 * @param data - объект с данными конкретного элемента
	 */
	public addSelectAffil( option: HTMLOptionElement ):void
	{
		let id:string = option.getAttribute( 'value' );
		let email: string = option.innerText;
		
		let liElement: HTMLLIElement;
		
		liElement = document.createElement( 'li' );
		this.affiliateList.appendChild(liElement);
		
		if ( id !== '' )
		{
			liElement.setAttribute( 'data-id', id);
			liElement.innerHTML = email;
		}
		
		this.affiliateList.appendChild(liElement);
	}
	
	/**
	 * Фукнция вставки данных в список доменов
	 * @param data - объект с данными конкретного элемента
	 */
	public addSelectDomain( data: any ): void
	{
		let key:any;
		let value: string;
		let liElement: HTMLLIElement;
		
		liElement = document.createElement( 'li' );
		this.domainList.appendChild(liElement);
		
		//liElement = document.createElement( 'li' );
		
		for ( key in data )
		{
			value = (<any>data)[key];
			
			if ( key === 'name' )
			{
				liElement.innerText = value;
			}
			
			liElement.setAttribute( 'data-' + key, value );
		}
		 /*if ( data.id !== '' )
		{
			let str: string = '';
			
			for ( key in data )
			{
				value = (<any>data)[key];
				
				str += `<span class="title">`+key+`:</span><span class="value">`+value+`</span>`;
				
				liElement.setAttribute( 'data-' + key, value );
			}
			
			liElement.innerHTML = str;
		}
		else
		{
			value = (<any>data).error;
			liElement.innerText = value;
		}*/
		
		this.domainList.appendChild(liElement);
	}
	
	/**
	 * Функция очиски списка аффилов
	 */
	public emptySelectOptionsAffil():void
	{
		this.affiliateList.innerHTML = '';
	}
	
	/**
	 * Функция очиски списка доменов
	 */
	public emptySelectOptionsDomains():void
	{
		this.domainList.innerHTML = '';
	}
	
	/**
	 * Функция обработки клика по списку аффилейтов
	 * Получаем текущее значение.
	 * Берём id и заносим его в аттрибут инпута домена
	 *
	 * @param e
	 */
	private customAffiliateListOptionsClickHandler( e?:MouseEvent ):void
	{
		let element: HTMLLIElement;
		
		if ( e )
		{
			element = (<HTMLLIElement>e.currentTarget);
			
			this.showDomainList();
		}
		else
		{
			element = <HTMLLIElement>this.customAffiliateList[0];
		}
		
		this.domainInput.setAttribute( 'data-id', element.getAttribute( 'data-id' ) );
		this.affiliateSelect.value = element.getAttribute( 'data-id' );
		this.affiliateLabel.innerText = element.innerText;
		
		this.hideAffiliateList();
		this.affiliateButtonErease.classList.add( SHOW );
		
		this.getDomainsDataFromAffil();
	}
	
	/**
	 * Функция обработки клика по списку доменов
	 * Получаем текущий выбранный домен и вставляем его в инпут домена.
	 * Запускаем сабмит формы.
	 *
	 * @param e
	 */
	private customDomainListOptionsClickHandler( e:MouseEvent ):void
	{
		let element: HTMLLIElement = (<HTMLLIElement>e.currentTarget);
		
		this.domainInput.value = element.getAttribute( 'data-name' );
		this.domainLabel.innerText = element.getAttribute( 'data-name' );
		
		this.hideDomainList();
		this.affiliateSearch = false;
		
		this.mainForm.submit();
	}
	
	/**
	 * Два прелоадера
	 */
	public createPreloaderAffil( ): void
	{
		this.affiliateList.classList.add( SEARCH );
	}
	public createPreloaderDomain( ): void
	{
		this.domainList.classList.add( SEARCH );
	}
	public removePreloaderAffil( ): void
	{
		this.affiliateList.classList.remove( SEARCH );
	}
	public removePreloaderDomain( ): void
	{
		this.domainList.classList.remove( SEARCH );
	}
	
	/**
	 * Функция показывает список с результатами поиска по аффилу
	 * @param e
	 */
	public showAffiliateList(e?:MouseEvent):void
	{
		if ( e )
		{
			if ( (<HTMLElement>e.currentTarget).nodeName  === 'LABEL' )
			{
				this.affiliateList.parentElement.classList.toggle( SHOW );
				this.hideDomainList();
			}
			else
			{
				this.affiliateList.parentElement.classList.add( SHOW );
			}
		}
		else
		{
			this.affiliateList.parentElement.classList.add( SHOW );
			this.hideDomainList();
		}
		
	}
	// Фукния скрывает список аффилейта
	public hideAffiliateList():void
	{
		this.affiliateList.parentElement.classList.remove( SHOW );
	}
	
	/**
	 * Функция показывает список с результатами поиска по домену
	 * @param e
	 */
	public showDomainList(e?:MouseEvent):void
	{
		if ( e )
		{
			if ( (<HTMLElement>e.currentTarget).nodeName  === 'LABEL' )
			{
				this.domainList.parentElement.classList.toggle( SHOW );
				this.hideAffiliateList();
			}
			else
			{
				this.domainList.parentElement.classList.add( SHOW );
			}
		}
		else
		{
			this.domainList.parentElement.classList.add( SHOW );
			this.hideAffiliateList();
		}
	}
	// Скрывает список доменов
	public hideDomainList():void
	{
		this.domainList.parentElement.classList.remove( SHOW );
	}
}