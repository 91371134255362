import ScrollMenu from '../classes/ScrollMenu';

export default function init(): void
{
	let menu: ScrollMenu;
	let element: HTMLUListElement;
	element = <HTMLUListElement>document.querySelector('ul.main-menu.index');
	
	if ( element )
	{
		menu = new ScrollMenu( element );
	}
	
	
}