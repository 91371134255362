import AdvType from '../classes/AdvType';

export default function init(): void
{
	let advType: AdvType;
	let element: HTMLElement;
	element = <HTMLElement>document.querySelector('main>section.description>div.block-preview');
	
	if ( element )
	{
		advType = new AdvType( element );
	}
	
	
}