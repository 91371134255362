/**
 * Класс обработки кликов по типам Adv
 */
export default class AdvType
{
	/**
	 * Родительский элемент
	 */
	private element: HTMLElement;
	/**
	 * Список кнопок-переключалок
	 */
	private buttonList: NodeList;
	/**
	 * Список контента для перключения
	 */
	private contentList: NodeList;
	/**
	 * Текущая кнпока
	 */
	private currentButton: HTMLButtonElement;
	/**
	 * Текущий элемент
	 */
	private currentContent: HTMLLIElement;
	
	public constructor( element: HTMLElement )
	{
		this.element = element;
		this.buttonList  = <NodeList>element.querySelectorAll( 'ul.controls>li>button' );
		this.contentList = <NodeList>element.querySelectorAll( 'ul.content>li' );
		
		this.currentButton = <HTMLButtonElement>this.buttonList[0];
		this.currentContent = <HTMLLIElement>this.contentList[0];
		
		Array.prototype.forEach.call(
			this.buttonList,
			( item: HTMLButtonElement ) =>
			{
				item.addEventListener( 'click', this.buttonClickHandler.bind(this) );
			}
		);
	}
	
	private buttonClickHandler( e: MouseEvent )
	{
		let button: HTMLButtonElement = <HTMLButtonElement>e.currentTarget;
		
		this.currentButton.classList.remove( 'active' );
		this.currentButton = button;
		this.currentButton.classList.add( 'active' );
		
		if ( button.classList.contains( 'desktop' ) )
		{
			Array.prototype.forEach.call(
				this.contentList,
				( item: HTMLLIElement ) =>
				{
					if ( item.classList.contains( 'desktop' ) )
					{
						this.currentContent.classList.remove( 'active' );
						this.currentContent = item;
						this.currentContent.classList.add( 'active' );
					}
				}
			);
		}
		else
		{
			Array.prototype.forEach.call(
				this.contentList,
				( item: HTMLLIElement ) =>
				{
					if ( item.classList.contains( 'mobile' ) )
					{
						this.currentContent.classList.remove( 'active' );
						this.currentContent = item;
						this.currentContent.classList.add( 'active' );
					}
				}
			);
		}
	}
}