import FindAffiliateStatistics from '../classes/findAffiliateStatistics';

export default function init(): void
{
	let findAffiliateStatistics: FindAffiliateStatistics;
	let element: HTMLElement;
	element = <HTMLElement>document.querySelector('div.filter.back-office');
	
	if ( element )
	{
		findAffiliateStatistics = new FindAffiliateStatistics( element );
	}
	
	
}