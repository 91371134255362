const SHOW = 'show';
const ACTIVE = 'active';

/// <reference path="jquery.d.ts" />
declare let $: any;

/**
 * Класс обработки кликов по кнопке показа/скрытия меню
 * Появление лайтбокса
 */
export default class ScrollMenu
{
	/**
	 * Кнопки-ссылки
	 */
	private buttons: NodeList;
	/**
	 * То куда скролим
	 */
	private anchors: NodeList;
	
	public constructor( element: HTMLUListElement )
	{
		this.buttons = <NodeList>element.querySelectorAll( 'li>a' );
		this.anchors = <NodeList>document.querySelectorAll( 'main>section' );
		
		Array.prototype.forEach.call(
			this.buttons,
			( item: HTMLAnchorElement ) =>
			{
				item.addEventListener( 'click', this.anchorClickHandler.bind(this) );
			}
		);
		
	}
	
	public anchorClickHandler( e: MouseEvent ): void
	{
		e.preventDefault();
		let timeout: number;
		let anchor: string = (<HTMLElement>e.currentTarget).getAttribute('href');
		let offsetTop = 0;
		
		// Если у нас клик по кнопке home, то откидываем в начало, иначе берём его высоту
		if ( anchor !== '#home' )
		{
			offsetTop = $(anchor).offset().top;
		}
		
		$( 'html, body' ).stop().animate( { scrollTop: offsetTop }, timeout );
	}
}