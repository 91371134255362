import Menu from '../classes/Menu';

export default function init(): void
{
	let menu: Menu;
	let element: HTMLButtonElement;
	element = <HTMLButtonElement>document.querySelector('header>button.menu');
	
	if ( element )
	{
		menu = new Menu( element );
	}
	else
	{
		element = <HTMLButtonElement>document.querySelector('header>div.wrapper>button.menu');
		
		if ( element )
		{
			menu = new Menu( element );
		}
	}
}