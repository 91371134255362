const SHOW = 'show';
const ACTIVE = 'active';
/**
 * Класс обработки кликов по кнопке показа/скрытия меню
 * Появление лайтбокса
 */
export default class Menu
{
	/**
	 * Кнопка появления меню
	 */
	private buttonMenu: HTMLButtonElement;
	/**
	 * Само меню
	 */
	private menu: HTMLElement;
	/**
	 * Чек меню
	 */
	private open: boolean;
	
	public constructor( element: HTMLButtonElement )
	{
		this.buttonMenu = element;
		this.open = false;
		this.menu = <HTMLBodyElement>document.querySelector( 'header>ul.main-menu' );
		
		if ( !this.menu )
		{
			this.menu = <HTMLBodyElement>document.querySelector( 'header>div.wrapper>ul.main-menu' );
		}
		
		this.buttonMenu.addEventListener( 'click', this.buttonMenuOpenClickHandler.bind(this) );
	}
	
	
	/**
	 * Закрываем меню
	 */
	private closeMenu()
	{
		this.menu.classList.remove( SHOW );
		this.buttonMenu.classList.remove( ACTIVE );
	}
	
	/**
	 * Открываем меню
	 * @param e
	 */
	private buttonMenuOpenClickHandler( e: MouseEvent )
	{
		if ( this.open === false )
		{
			this.menu.classList.add( SHOW );
			this.buttonMenu.classList.add( ACTIVE );
			this.open = true
		}
		else
		{
			this.closeMenu();
			this.open = false
		}
	}
}