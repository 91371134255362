
/*

import addBasket from './common.src/modules/temp/addBasket';
import orders from './common.src/modules/temp/orders';
import findBug from './common.src/modules/temp/findBug';
import callMe from './common.src/modules/temp/callMe';
import iWantOrder from './common.src/modules/temp/iWantOrder';
import filter from './common.src/modules/filter';


addBasket();
orders();
findBug();
callMe();
iWantOrder();
filter();*/

import localStorage from './src/modules/localStorage'
import advType from './src/modules/advType'
import login from './src/modules/login'
import menu from './src/modules/menu'
import scrollMenu from './src/modules/scrollMenu'
import boneIE from './src/modules/boneIE'
import findAffiliateStatistics from './src/modules/findAffiliateStatistics'

localStorage();
advType();
login();
menu();
scrollMenu();
boneIE();
findAffiliateStatistics();
