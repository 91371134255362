const LIGHTBOX = 'lightbox';

/**
 * Класс обработки кликов по кнопке логина.
 * Появление лайтбокса
 */
export default class Login
{
	/**
	 * Кнопка появления лайтбокса
	 */
	private buttonLightbox: HTMLButtonElement;
	/**
	 * Наш фон
	 */
	private background: HTMLDivElement;
	/**
	 * body html node
	 */
	private body: HTMLBodyElement;
	/**
	 * body html node
	 */
	private lightbox: HTMLElement;
	/**
	 *
	 */
	private loginTitle: HTMLElement;
	
	public constructor( element: HTMLButtonElement )
	{
		this.buttonLightbox = element;
		this.body = <HTMLBodyElement>document.querySelector( 'body' );
		this.lightbox = <HTMLElement>document.querySelector('html>body>header>ul.login-panel>li.login');
		
		this.buttonLightbox.addEventListener( 'click', this.buttonLightboxClickHandler.bind(this) );
		
		this.createBackground();
	}
	
	/**
	 * Создание бэкграунда
	 */
	private createBackground()
	{
		let backElement: HTMLDivElement = document.createElement( 'div' );
		this.loginTitle = document.createElement( 'h2' );
		
		this.loginTitle.innerText = 'Login';
		
		backElement.classList.add( 'background-login' );
		backElement.classList.add( 'hide' );
		
		this.lightbox.appendChild( backElement );
		
		this.background = backElement;
		this.background.addEventListener( 'click', this.closeLightbox.bind(this) );
		
	}
	
	/**
	 * Закрываем лайтбокс
	 * @param e
	 */
	private closeLightbox(e: MouseEvent)
	{
		this.background.classList.add( 'hide' );
		this.body.classList.remove( LIGHTBOX );
		this.lightbox.classList.remove( LIGHTBOX );
		this.lightbox.querySelector( 'form' ).removeChild( this.loginTitle );
	}
	
	/**
	 * Показываем лайтбокс
	 * @param e
	 */
	private buttonLightboxClickHandler( e: MouseEvent )
	{
		this.background.classList.remove( 'hide' );
		this.body.classList.add( LIGHTBOX );
		this.lightbox.classList.add( LIGHTBOX );
		this.lightbox.querySelector( 'form' ).appendChild( this.loginTitle );
		
	}
}