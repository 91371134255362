export default function init(): void
{
	let userAgent: string = window.navigator.userAgent;
	let match: Array<string>;
	let regex: RegExp = /\.NET CLR /g;
	
	match = userAgent.match( regex );

	if ( match )
	{
		document.querySelector( 'body' ).style.display = 'block';
	}
}